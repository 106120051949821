// extracted by mini-css-extract-plugin
export var action = "styles-module--action--nnXSj";
export var container = "styles-module--container--Z42hX";
export var contentContainer = "styles-module--contentContainer--d1Rl2";
export var controlsContainer = "styles-module--controlsContainer--g5eN6";
export var header = "styles-module--header---0eWl";
export var hr = "styles-module--hr--UPWK-";
export var image = "styles-module--image--EDy9v";
export var imageContainer = "styles-module--imageContainer--QmHbh";
export var item = "styles-module--item--fCO9N";
export var label = "styles-module--label--2MCCH";
export var priceContainer = "styles-module--priceContainer--WRwQm";
export var quantityContainer = "styles-module--quantityContainer---SOYK";
export var sumContainer = "styles-module--sumContainer--2w17R";
export var table = "styles-module--table--cXmcT";