import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../../../components/elements/Button"
import Card from "../../../../components/elements/Card"
import TextInput from "../../../../components/elements/Inputs/TextInput"
import AccountNavigation from "../../Navigation"
import axios from "axios";
import { getUserToken } from "../../../../services/auth"
import LoadingSpinner from "../../../../components/elements/LoadingSpinner"
import { API_BASE } from "../../../../spectory-config"
import { renderDate } from "../../../../functions/date"

import * as cartStyles from '../../../warenkorb/styles.module.scss';
import { floatToString } from "../../../../functions/money"

const Order = (props) => {

    const [order, setOrder] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        var config = {
            method: 'post',
            url: `${API_BASE}/customer/order`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                token: getUserToken(),
                "order_id": props.orderId
            }
        };


        axios(config)
            .then(function (response) {
                console.log("response", response.data.data)

                setOrder(response.data.data)

                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
            });

    }, [])

    const getInvoice = () => {

    }


    const renderOrder = () => {
        return <>
            <div className="container fluid" style={{ padding: 0 }}>
                <div className="col8" style={{ padding: 0 }}>
                    <table style={{ width: '100%', marginLeft: -5 }} cellPadding="5">
                        <tr>
                            <td>Datum</td>
                            <td>{renderDate(order.dates.created.date)}</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>{order.status}</td>
                        </tr>
                        <tr>
                            <td>Zahlung</td>
                            <td>{renderDate(order.dates.created.date)}</td>
                        </tr>
                        <tr>
                            <td>Adresse</td>
                            <td>{order.shiping_data.replace(/<br\/>/g, ', ')}</td>
                        </tr>
                    </table>
                </div>
                <div className="col4" style={{ padding: 0 }}>
                    <div>
                        <Button
                            title="Zurück"
                            destination="/konto/orders"
                            type="secondary"
                        />
                    </div>
                </div>
            </div>

            <div
                className={`${cartStyles.container}`}
                style={{ marginTop: 50 }}
            >
                <div className={`${cartStyles.item} ${cartStyles.header}`}>
                    <div className={cartStyles.imageContainer}>
                        <div className={cartStyles.label}>Produkt</div>
                    </div>
                    <div className={cartStyles.contentContainer}></div>
                    <div className={cartStyles.priceContainer}>
                        <div className={cartStyles.label}>Preis</div>
                    </div>
                    <div className={cartStyles.quantityContainer}>
                        <div className={cartStyles.label}>Menge</div>
                    </div>
                    <div className={cartStyles.sumContainer}>
                        <div className={cartStyles.label}>Gesamt</div>
                    </div>
                </div>
                {
                    order.items.map(item => {

                        let price = parseFloat(item.total);
                        price = price * item.quantity;

                        return <div className={cartStyles.item}>
                            <div className={cartStyles.imageContainer}>
                                <div className={cartStyles.image} style={{ backgroundImage: `url(${item.image})` }}></div>
                            </div>
                            <div className={cartStyles.contentContainer}>
                                <h4 className={cartStyles.title}>{item.title}</h4>
                                {
                                    item.coupon_url !== undefined && <a target="_blank" href={item.coupon_url}>Gutschein herunterladen</a>
                                }
                                <div>{item.detail}</div>
                            </div>
                            <div className={cartStyles.priceContainer}>
                                € {floatToString(parseFloat(item.total))}
                            </div>
                            <div className={cartStyles.quantityContainer}>
                                {item.quantity}
                            </div>
                            <div className={cartStyles.sumContainer}>
                                € {floatToString(parseFloat(item.total) * item.quantity)}
                            </div>
                        </div>
                    })
                }

            </div>

            <div className={"container"} style={{ marginTop: 50 }}>
                <div className="col7">
                    <div>
                        <Button
                            type="secondary"
                            title="Rechnung herunterladen"
                            action={getInvoice}
                        />
                    </div>
                </div>
                <div className="col5">
                    <div>
                        <table style={{ width: '100%' }} cellPadding="5">
                            <tr>
                                <td>Zwischensumme</td>
                                <td>€ {floatToString(parseFloat(order.payment.subtotal))}</td>
                            </tr>
                            <tr>
                                <td>Versand</td>
                                <td>€ {floatToString(parseFloat(order.payment.shipping))}</td>
                            </tr>
                            <tr>
                                <td><strong style={{ textTransform: 'uppercase' }}>Gesamtsumme</strong></td>
                                <td>€ {floatToString(parseFloat(order.payment.total))}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </>
    }


    return (
        <div className="container" style={{ paddingTop: 100, paddingBottom: 100 }}>
            <div className="col4">
                <AccountNavigation current={'konto/orders'} />
            </div><div className="col8">
                <div>
                    <h4>Bestellung {props.orderId}</h4>

                    {
                        loading ? <LoadingSpinner active={loading} type="container" /> :
                            renderOrder()
                    }
                </div>
            </div>

        </div>
    )
}

export default Order
