import React, { useEffect, useRef, useState } from "react"
import * as styles from './style.module.scss';
// import Inputmask from "inputmask";

import EyeOpen from '../../../images/Icons/eye-close-up.svg';
import InfoIcon from '../../../images/Icons/info.svg';
import EyeClosed from '../../../images/Icons/close-eyes.svg';
import CardIcon from '../../../images/Icons/card-icon.svg';
import { validate } from "../../../services/validation";

const TextInput = (props) => {

    const [focused, setFocused] = useState(false);
    const [realFocused, setRealFocused] = useState(false);
    const [value, setValue] = useState('');
    const [init, setInit] = useState(true);

    const [hasError, setHasError] = useState(false);
    const [hasSuccess, setHasSuccess] = useState(false);

    const inputRef = useRef();

    const [type, setType] = useState(props.type || 'text');

    let mask = null;

    let rules = {};

    if (props.required) {
        rules.isRequired = true;
    }
    if (props.type == 'email') {
        rules.isRequired = true;
        rules.isEmail = true;
    }
    if (props.type == "password") {

    }

    // if (window !== undefined) {

    //     if (props.type === 'cardNumber') {
    //         rules.isRequired = true;
    //         rules.isCreditCardNumber = true;

    //         mask = new Inputmask({ "mask": "9999 9999 9999 9999", placeholder: '' });
    //     }

    //     if (props.type === 'cardExpiry') {
    //         rules.isRequired = true;
    //         rules.isCreditCardExpiry = true;

    //         mask = new Inputmask({ "mask": "99/99", placeholder: ' ' });

    //     }

    //     if (props.type === 'cardCVC') {
    //         rules.isRequired = true;
    //         rules.isCreditCardCVC = true;

    //         mask = new Inputmask({ "mask": "9{3,5}", placeholder: ' ' });
    //     }

    //     if (props.type === 'price') {
    //         mask = new Inputmask({ "mask": "€ 9{1,10},00", placeholder: ' ' });
    //     }

    //     if (props.type === 'coupon') {
    //         mask = new Inputmask({ "mask": "****-****", placeholder: ' ' });
    //     }

    // }

    useEffect(() => {
        if (mask !== null) {
            mask.mask(inputRef.current);
        }
    }, [])

    useEffect(() => {

        if (props.reset === true) {
            setValue('');
            setHasError(false);
            setHasSuccess(false);
        }

    }, [props.reset])


    console.log("props.value", props);

    const handleChange = () => {

        if (Object.keys(rules).length < 1) return;

        let valid = validate(value, rules);

        if (typeof (props.setValid) !== 'undefined') {
            props.setValid(valid);
        }

        if (valid) {
            setHasSuccess(true);
            setHasError(false);
        } else {
            setHasError(true);
            setHasSuccess(false);
        }

    }

    useEffect(() => {
        if (props.checkValidity === true) {
            handleChange();
        }
    }, [props.checkValidity])

    useEffect(() => {
        if (realFocused || value !== '') {
            setFocused(true)
        } else {
            setFocused(false)
        }

    }, [realFocused, value]);

    useEffect(() => {
        if (!init) {
            handleChange();
        }
        setInit(false)
    }, [value])

    useEffect(() => {

        if (props.initValue !== undefined && props.initValue !== '') {
            setValue(props.initValue);
        }

    }, [props.initValue])


    return (
        <div
            className={`${styles.container} ${realFocused ? styles.focus : null}  ${focused ? styles.labelUp : null} ${hasError ? styles.error : null} ${hasSuccess ? styles.success : null}`}
            style={props.style}
        >
            <label>
                <div className={`${styles.label}`}>
                    {props.label}
                    {props.description !== undefined && props.description !== '' && <div className={styles.infoContainer}>
                        <InfoIcon />
                        <div className={styles.textContainer}>{props.description}</div>
                    </div>
                    }
                </div>
                {props.multiline ?
                    <textarea
                        {...props}
                        type={type}
                        value={value}
                        ref={inputRef}
                        onChange={(e) => { setValue(e.target.value); props.onChange(e) }} onFocus={() => setRealFocused(true)} onBlur={() => {
                            setRealFocused(false);
                            console.log("props.onBlur", props.onBlurDo)
                            if (props.onBlurDo !== undefined) {
                                props.onBlurDo()
                            }
                        }}
                    >
                        {value}
                    </textarea>
                    :
                    <input
                        {...props}
                        className={props.type === 'password' ? styles.password : null}
                        type={type}
                        value={value}
                        ref={inputRef}
                        onChange={(e) => { setValue(e.target.value); props.onChange(e) }} onFocus={() => setRealFocused(true)} onBlur={() => {
                            setRealFocused(false);
                            console.log("props.onBlur", props.onBlurDo)
                            if (props.onBlurDo !== undefined) {
                                props.onBlurDo()
                            }
                        }}
                    />
                }


            </label>
            {
                props.type === 'password' && <div
                    className={styles.eyesContainer}
                    onClick={() => setType(prev => { return prev == 'password' ? 'text' : 'password' })}
                >
                    {type === 'password' ? <EyeClosed /> : <EyeOpen />}
                </div>
            }
            {
                props.type === 'cardNumber' && <CardIcon className={styles.cardIcon} />
            }

        </div >
    )


}

export default TextInput;
