export const renderDate = (dateTimeString) => {

    dateTimeString = dateTimeString.replace(' ', 'T');

    let dateTime = new Date(dateTimeString);

    let month = (dateTime.getMonth() + 1);
    if(month < 10) month = '0'+month;

    let day = dateTime.getDate();
    if(day < 10) day = '0'+day;

    return day + '.' + month + '.' + dateTime.getFullYear();
}