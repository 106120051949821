export const strPriceToFloat = (string) => {

    return parseFloat(string.replace(',', '.'));

}

export const floatToString = (float) => {

    if(!float || float == undefined || float == null) float = 0.0;

    if (typeof float === "string") {
    }
    float = parseFloat(float);

    return float.toFixed(2).toString().replace('.', ',');

}